import { useState, useEffect, useRef, JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from 'react';
import { PageTemplate } from '../../PageTemplate';
import { FloatingLabel, Modal } from 'react-bootstrap';
import './styles.scss';
import { doc, setDoc, getDoc } from '@firebase/firestore';
import { db, storage } from '../../../firebase-config';
import { GYM_STORE_REF } from '../../../Utilities/dbRefs';
import { useStoreContext } from '../../../Utilities/globalState';
import { Nav } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { functions } from '../../../firebase-config'
import { QRCodeSVG } from 'qrcode.react';
import { Form, InputGroup, Spinner, Alert } from 'react-bootstrap'
import { v4 as randomUUID } from 'uuid';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { reportNonBlockingError } from '../../../Utilities/helpers';
import { report } from 'process';
import { Space, Table, Tag, Descriptions, Badge } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type { TableProps, DescriptionsProps } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';

interface StoreProps {
    itemName: string;
    itemPrice: number;
    itemDescription: string | null;
    itemImageUUID: string | null;
    itemImage: string | null;
}

export default function Store() {
    const [state, dispatch] = useStoreContext();
    const [cart, setCart] = useState<StoreProps[]>([])
    const [showCart, setShowCart] = useState(false);
    const [storeItems, setStoreItems] = useState<StoreProps[]>([]);
    const [paymentPackages, setPaymentPackages] = useState<any[]>(state.currentUser.paymentPackages || []);
    const [loading, setLoading] = useState(true);
    const [showNewItemModal, setShowNewItemModal] = useState(false);
    const itemNameRef = useRef<HTMLInputElement>(null);
    const itemPriceRef = useRef<HTMLInputElement>(null);
    const itemDescriptionRef = useRef<HTMLTextAreaElement>(null);
    const itemImageRef = useRef<HTMLInputElement>(null);
    const [itemImageUUID, setItemImageUUID] = useState('');
    const [itemImageURL, setItemImageURL] = useState('')
    const [itemImageLoading, setItemImageLoading] = useState(false);
    const [page, setPage] = useState(1)
    const { hash } = useLocation()
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [qrLoading, setQrLoading] = useState(false);
    const [stripeSessionToTrack, setStripeSessionToTrack] = useState('')
    const [stripeSessions, setStripeSessions] = useState<any[]>([])
    const [purchaseComplete, setPurchaseComplete] = useState(false);
    const [stripeError, setStripeError] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState<StoreProps | null>(null);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const itemAdded = () => toast.success("Item added to store!");
    const itemDeleted = () => toast.success("Item deleted from store!");
    const refundSuccessful = () => toast.success("Customer refunded!");
    const [showDangerRefundButton, setShowDangerRefundButton] = useState(false);

    const [itemName, setItemName] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [itemDescription, setItemDescription] = useState('');

    const isFormValid = () => {
        return itemName && itemPrice && itemDescription && itemImageUUID;
    };

    function addItemToStore() {
        const newStoreItem: StoreProps = {
            itemName: itemNameRef.current?.value || '',
            itemPrice: Number(itemPriceRef.current?.value) || 0,
            itemDescription: itemDescriptionRef.current?.value || '',
            itemImageUUID: itemImageUUID,
            itemImage: itemImageURL,
        }
        setStoreItems([...storeItems, newStoreItem]);
        itemAdded();
    }

    function editItemInStore() {
        if (!itemToEdit) return;
        const newStoreItems = storeItems.map(item => {
            if (item === itemToEdit) {
                return {
                    itemName: itemNameRef.current?.value || '',
                    itemPrice: Number(itemPriceRef.current?.value) || 0,
                    itemDescription: itemDescriptionRef.current?.value || '',
                    itemImage: itemImageURL || item.itemImage,
                    itemImageUUID: item.itemImageUUID,
                }
            }
            return item;
        })
        setStoreItems(newStoreItems);
    }
    function removeItemFromStore(index: number) {
        const newStoreItems = storeItems.filter((item, i) => i !== index);
        // console.log(newStoreItems);
        setStoreItems([...newStoreItems]);
        itemDeleted();
    }

    function uploadImageToStorage() {
        const file = itemImageRef.current?.files?.[0];
        if (!file) return;
        setItemImageLoading(true);
        const newUUID = randomUUID();
        const storageRef = ref(storage, 'storeImages/' + newUUID);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        // console.log('Upload is paused');
                        break;
                    case 'running':
                        // console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // console.log('Upload successful');
                setItemImageUUID(newUUID);
                const downloadURL = getDownloadURL(uploadTask.snapshot.ref);
                downloadURL.then((url) => {
                    setItemImageURL(url);
                });
                setItemImageLoading(false);
            }
        );
    }

    function handleCloseCart() {
        setShowCart(false);
        setPurchaseComplete(false);
        setStripeSessionToTrack('');
        setQrCodeValue('');
    }

    function handleRemoveCartItem(index: number) {
        const newCart = cart.filter((item, i) => i !== index);
        localStorage.setItem('cart', JSON.stringify(newCart));
        setCart(newCart);
    }

    function sortStoreItemsByPrice() {
        const sortedItems = [...storeItems].sort((a, b) => a.itemPrice - b.itemPrice);
        setStoreItems(sortedItems);
    }

    function sortStoreItemsByName() {
        const sortedItems = [...storeItems].sort((a, b) => a.itemName.localeCompare(b.itemName));
        setStoreItems(sortedItems);
    }
    function handleNewItemModalClose() {
        setShowNewItemModal(false);
        // Clear the form state
        setItemName('');
        setItemPrice('');
        setItemDescription('');
    }


    function checkoutWithStripe() {
        const premium = state.currentUser?.moreGymsPremium?.status === 'active'
            || state.currentUser?.moreGymsPremium?.status === 'trialing';

        if (!cart.length) return;

        let createStripeCheckoutSession: HttpsCallable | undefined;

        if (premium) {
            createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSessionPremium');
        } else {
            createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');
        }

        setQrLoading(true);

        const items = cart.map(item => {
            return {
                price_data: {
                    currency: 'usd',
                    product_data: {
                        name: item.itemName || 'Item',
                    },
                    unit_amount: item.itemPrice * 100,
                },
                quantity: 1,
            }
        })
        createStripeCheckoutSession({ items, id: state.currentUser.stripeData.id })
            .then((response: any) => {
                if (response.data) {
                    const { id, url } = response.data;
                    setQrCodeValue(url);
                    setStripeSessionToTrack(id);
                    setQrLoading(false);
                }
            })
            .catch((error: any) => {
                reportNonBlockingError(error, state, 'Store -> checkoutWithStripe -> createStripeCheckoutSession');
                setQrLoading(false);
            })
    }

    function handleRefund(sessionID: string) {
        // const areYouSure = window.confirm('Are you sure you want to refund this payment?');
        // if (!areYouSure) return;
        const refundSession: HttpsCallable = httpsCallable(functions, 'refundStripeSession')
        setShowPaymentModal(false);
        refundSuccessful();
        refundSession({ sessionID, id: state.currentUser.stripeData.id })
            .then((response: any) => {
                if (response.data) {
                    // console.log(response.data);
                }
            })
            .catch((error: any) => {
                reportNonBlockingError(error, state, 'Store -> handleRefund -> refundSession');
            })

    };

    function addItemToCart(item: StoreProps) {
        localStorage.setItem('cart', JSON.stringify([...cart, item]));
        setCart([...cart, item]);
    }

    function renderPage() {
        switch (page) {
            case 1:
                return ProductsDisplay();
            case 2:
                return MembershipsDisplay();
            case 3:
                return PaymentsDisplay();
            default:
                return ProductsDisplay();
        }
    }

    function updateStripeSessions() {
        const getAllStripeSessions: HttpsCallable = httpsCallable(functions, 'getAllStripeSessions')
        getAllStripeSessions({ id: state.currentUser.stripeData.id })
            .then((response: any) => {
                if (response.data) {
                    setStripeSessions(response.data.data);
                }
            })
            .catch((error: any) => {
                reportNonBlockingError(error, state, 'Store -> updateStripeSessions -> getAllStripeSessions');
            })
    }

    function getStripeLoginLink() {
        const getStripeLoginLink: HttpsCallable = httpsCallable(functions, 'createStripeConnectLoginLink')
        getStripeLoginLink({ id: state.currentUser.stripeData.id })
            .then((response: any) => {
                if (response.data) {
                    const { url } = response.data;
                    window.open(url, '_blank')?.focus();
                }
            })
            .catch((error: any) => {
                reportNonBlockingError(error, state, 'Store -> getStripeLoginLink -> getStripeLoginLink');
            })
    }

    const handleKeyDown = (event: { key: string; preventDefault: () => void; }) => {
        if (event.key === '-' || event.key === 'Minus') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (state.currentUser.stripeData) {
            if (state.currentUser.stripeData.requirements.currently_due.length ||
                state.currentUser.stripeData.requirements.past_due.length ||
                state.currentUser.stripeData.requirements.pending_verification.length ||
                state.currentUser.stripeData.requirements.errors.length ||
                state.currentUser.stripeData.charges_enabled === false ||
                state.currentUser.stripeData.payouts_enabled === false ||
                state.currentUser.stripeData.details_submitted === false
            ) {
                setStripeError(true);
            } else {
                setStripeError(false);
            }
        }
    }, [state.currentUser.stripeData])

    useEffect(() => {
        if (loading) return;
        // Save store items to firebase
        // console.log('saving store items to firebase');
        const storeRef = doc(db, GYM_STORE_REF, state.currentUser.gymId);
        setDoc(storeRef, { storeItems });
    }, [storeItems, loading]);

    useEffect(() => {
        if (hash === '#products') {
            setPage(1)
        }
        else if (hash === '#memberships') {
            setPage(2)
        }
        else if (hash === '#payments') {
            setPage(3)
        }
        else {
            setPage(1)
        }
    }, [hash])

    // check to see recent changes in checkout sessions
    useEffect(() => {
        if (!stripeSessionToTrack.length) return;
        const sessionInterval = setInterval(() => {
            const checkSessionStatus: HttpsCallable = httpsCallable(functions, 'checkSessionStatus')
            checkSessionStatus({ sessionID: stripeSessionToTrack, id: state.currentUser.stripeData.id })
                .then((response: any) => {
                    if (response.data) {
                        if (response.data.status === 'complete') {
                            setCart([]);
                            // localStorage.setItem('cart', JSON.stringify([]));
                            setStripeSessionToTrack('');
                            clearInterval(sessionInterval);
                            setPurchaseComplete(true);
                            const cartItemsString = localStorage.getItem('cart');
                            let cartItems = [];

                            if (cartItemsString) {
                                cartItems = JSON.parse(cartItemsString);
                            }

                            const { amount_total, created, customer_details, id, currency } = response.data;
                            const { email, name } = customer_details;

                            const listItems = convertItemsToList(cartItems, currency);

                            const emailData = {
                                to: email,
                                created: created,
                                name: name,
                                amountTotal: amount_total,
                                id: id,
                                currency: currency,
                                list_items: listItems,
                                gymName: state.currentUser?.gymName,
                                gymId: state.currentUser?.gymId,
                            };

                            setTimeout(() => {
                                console.log("Sending email data after delay:", emailData);

                                const sendUserReceipt = httpsCallable(functions, 'sendUserReceipt');
                                sendUserReceipt(emailData)
                                    .then(() => {
                                        console.log("Receipt email sent!");
                                    })
                                    .catch((error: any) => {
                                        console.error("Error sending receipt email:", error);
                                    });
                                localStorage.setItem('cart', JSON.stringify([]));
                            }, 4000);
                            updateStripeSessions();
                        }
                    }
                })
                .catch((error: any) => {
                    reportNonBlockingError(error, state, 'Store -> updateStripeSessions -> checkSessionStatus');
                    updateStripeSessions();
                })
        }, 7000)
        return () => clearInterval(sessionInterval);
    }, [stripeSessionToTrack])
    const currencySymbols: { [key: string]: string } = {
        usd: "$",
        eur: "€",
        gbp: "£",
        jpy: "¥",
        aud: "A$",
        cad: "C$",
        chf: "CHF",
        cny: "¥",
        sek: "kr",
        nzd: "NZ$",
    };

    function convertItemsToList(items: any[], currency: string) {
        const formattedCurrency = currencySymbols[currency.toLowerCase()] || currency;

        // Use reduce to group items by itemName and sum their quantities
        const itemMap = items.reduce((acc, item) => {
            const existingItem = acc.find((accItem: { itemName: any; itemPrice: any; }) => accItem.itemName === item.itemName && accItem.itemPrice === item.itemPrice);
            if (existingItem) {
                existingItem.quantity += 1;  // Increment quantity if item exists
            } else {
                acc.push({
                    itemName: item.itemName,
                    itemPrice: item.itemPrice,
                    quantity: 1  // Start quantity at 1 for new items
                });
            }
            return acc;
        }, []);

        // Map itemMap to the desired list_items format
        return itemMap.map((item: { itemName: any; quantity: { toString: () => any; }; itemPrice: number; }, index: any) => ({
            itemName: item.itemName,  // Rename itemName to name
            quantity: item.quantity.toString(),  // Convert quantity to string
            itemPrice: `${formattedCurrency}${item.itemPrice.toFixed(2)}`,
        }));
    }

    useEffect(() => {
        updateStripeSessions()
        if (!cart.length) {
            const cartFromStorage = localStorage.getItem('cart');
            if (cartFromStorage) {
                setCart(JSON.parse(cartFromStorage));
            }
        }

        // Fetch store items from firebase
        const storeRef = doc(db, GYM_STORE_REF, state.currentUser.gymId);
        getDoc(storeRef)
            .then((doc) => {
                if (doc.exists()) {
                    setStoreItems(doc.data().storeItems);
                }
            })
            .catch((error) => {
                reportNonBlockingError(error, state, 'Store -> useEffect -> getDoc(storeRef)');
            }
            );

        setTimeout(() => {
            setLoading(false);
        }, 1000);

    }, []);

    useEffect(() => {
        if (showPaymentModal) {
            setShowDangerRefundButton(false);
        }
    }, [showPaymentModal]);

    return (
        <PageTemplate>
            <div className="cart-button-container">
                <button disabled={!cart.length} onClick={() => setShowCart(true)} className='custom-button '><i className="bi bi-cart cart-icon"></i>Cart {cart.length}</button>
            </div>
            <div className='store-title'>
                <h1>Store</h1>
                {stripeError && <Alert variant='danger'>You need to complete your stripe <a href='/settings#payment-settings'>account setup</a> before you can use the store.</Alert>}
            </div>
            <Nav justify variant="tabs" activeKey={`link-${page}`}>
                <Nav.Item className="info-nav-link">
                    <Nav.Link href="/store#products" eventKey="link-1">
                        Products
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="info-nav-link">
                    <Nav.Link href='/store#memberships' eventKey="link-2" disabled={stripeError}>
                        Memberships
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="info-nav-link">
                    <Nav.Link href='/store#payments' eventKey="link-3" disabled={stripeError}>
                        Payments
                    </Nav.Link>
                </Nav.Item>
            </Nav>


            <div className="store-body">
                {renderPage()}
            </div>

            {/* NEW ITEM MODAL */}
            <Modal show={showNewItemModal} onHide={handleNewItemModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <InputGroup size="sm" className="mb-3">
                            <FloatingLabel controlId="itemName" label="Item Name">
                                <Form.Control
                                    ref={itemNameRef}
                                    type="text"
                                    placeholder="Item Name"
                                    value={itemName}
                                    onChange={(e) => setItemName(e.target.value)}
                                />
                            </FloatingLabel>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Text className="currency-style" id="currency-icon-box">
                                <i className="bi bi-currency-dollar" />
                            </InputGroup.Text>
                            <FloatingLabel controlId="itemPrice" label="Item Price">
                                <Form.Control
                                    ref={itemPriceRef}
                                    type="number"
                                    step={"0.01"}
                                    placeholder="Item Price"
                                    min="0"
                                    value={itemPrice}
                                    onChange={(e) => setItemPrice(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                            </FloatingLabel>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <FloatingLabel controlId="itemDescription" label="Item Description">
                                <Form.Control
                                    ref={itemDescriptionRef}
                                    as="textarea"
                                    placeholder="Item Description"
                                    value={itemDescription}
                                    onChange={(e) => setItemDescription(e.target.value)}
                                    style={{ resize: 'none' }}
                                />
                            </FloatingLabel>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <Form.Control
                                ref={itemImageRef}
                                type="file"
                                id="itemImage"
                                accept="image/png, image/jpeg"
                                onChange={uploadImageToStorage}
                            />
                        </InputGroup>
                    </form>
                </Modal.Body>
                {/* <Modal.Body>
                    <form>
                        <InputGroup size="sm" className="mb-3">
                            <FloatingLabel controlId="itemName" label="Item Name">
                                <Form.Control ref={itemNameRef} type="text" placeholder="Item Name" />
                            </FloatingLabel>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <FloatingLabel controlId="itemPrice" label="Item Price">
                                <Form.Control ref={itemPriceRef} type="number" step={"0.01"} placeholder="Item Price" min="0" onKeyDown={handleKeyDown} />
                            </FloatingLabel>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <FloatingLabel controlId="itemDescription" label="Item Description">
                                <Form.Control ref={itemDescriptionRef} as="textarea" placeholder="Item Description" />
                            </FloatingLabel>
                        </InputGroup>

                        <InputGroup size="sm" className="mb-3">
                            <Form.Control
                                ref={itemImageRef}
                                onChange={uploadImageToStorage}
                                type="file"
                                id="itemImage"
                                accept="image/png, image/jpeg" // Specify accepted file types if needed
                            />
                        </InputGroup>
                    </form>
                </Modal.Body> */}
                <Modal.Footer>
                    {/* <button className='custom-button-second' onClick={() => setShowNewItemModal(false)}>
                        Close
                    </button> */}
                    <button disabled={!isFormValid() || itemImageLoading} className='custom-button new-product' onClick={() => {
                        addItemToStore()
                        setShowNewItemModal(false)
                    }}>
                        Add Product
                    </button>
                </Modal.Footer>
            </Modal>

            {/* CART MODAL */}
            <Modal show={showCart} onHide={handleCloseCart}>
                <Modal.Header closeButton>
                    <Modal.Title>Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="cart-items">
                        {cart.map((item, index) => {
                            return (
                                <div key={index} className="cart-item">
                                    <div className="section">

                                        <h3>{item.itemName}</h3>
                                        <p>${Number(item.itemPrice).toFixed(2)}</p>
                                    </div>
                                    <div className="section">
                                        <button className='custom-button-second' onClick={() => handleRemoveCartItem(index)}>X</button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="qr-code-container">
                        {qrLoading && <h3>Loading your QR code...</h3>}
                        {qrCodeValue.length && !purchaseComplete ?
                            <>
                                <h3>Scan this QR code to complete your purchase</h3>
                                <QRCodeSVG value={qrCodeValue} />
                            </> :
                            null}
                        {purchaseComplete && <h3>Purchase Complete!</h3>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='custom-button-second' onClick={handleCloseCart}>
                        Close
                    </button>
                    {!purchaseComplete && !qrLoading &&
                        <button className='custom-button' onClick={checkoutWithStripe}>
                            Checkout
                        </button>}
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </PageTemplate>
    )

    function ProductsDisplay() {

        const columns: TableProps<StoreProps>['columns'] = [
            {
                title: 'Image',
                dataIndex: 'itemImage',
                key: 'itemImage',
                render: (itemImage) => itemImage ? <img src={itemImage} alt="item" style={{ width: '50px', height: '50px', borderRadius: '4px', objectFit: 'cover' }} /> : null,
            },
            {
                title: 'Name',
                dataIndex: 'itemName',
                key: 'itemName',
            },
            {
                title: 'Description',
                dataIndex: 'itemDescription',
                key: 'itemDescription',
            },
            {
                title: 'Price',
                dataIndex: 'itemPrice',
                key: 'itemPrice',
                render: (itemPrice) => `$${itemPrice.toFixed(2)}`,
            },
            {
                title: 'Actions',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <button className='custom-button-fourth ' onClick={() => addItemToCart(record)}>Add to Cart</button>
                        <button className='custom-button-fourth ' onClick={() => {
                            setShowEditItemModal(true);
                            setItemToEdit(record);
                        }}>Edit</button>
                        {/* <button className='custom-button-fourth ' onClick={() => removeItemFromStore(storeItems.indexOf(record))}>Delete</button> */}
                    </Space>
                ),
            },
        ];
        return (
            <>
                <div className="store-controls">
                    <button className='custom-button-fourth ' onClick={sortStoreItemsByPrice}>Sort by Price</button>
                    <button className=' custom-button-fourth ' onClick={sortStoreItemsByName} >Sort by Name</button>
                    <button className=' custom-button-fourth ' onClick={() => setShowNewItemModal(true)} disabled={stripeError}>Add Item</button>
                </div>
                <div className="store-items">
                    {loading && <div className='spinner-container'><Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>}
                    {!loading && <Table columns={columns} dataSource={storeItems} pagination={{ pageSize: 5 }} />}
                    {showEditItemModal && <Modal show={showEditItemModal} onHide={() => setShowEditItemModal(false)} >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Product</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <InputGroup size="sm" className="mb-3">
                                    <FloatingLabel controlId="itemName" label="Item Name">
                                        <Form.Control ref={itemNameRef} type="text" placeholder="Item Name" defaultValue={itemToEdit?.itemName || ""} />
                                    </FloatingLabel>
                                </InputGroup>

                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text className="currency-style" id="currency-icon-box">
                                        <i className="bi bi-currency-dollar" />
                                    </InputGroup.Text>
                                    <FloatingLabel controlId="itemPrice" label="Item Price">
                                        <Form.Control ref={itemPriceRef} type="number" step={"0.01"} placeholder="Item Price" min="0" onKeyDown={handleKeyDown} defaultValue={itemToEdit?.itemPrice || 0} />
                                    </FloatingLabel>
                                </InputGroup>

                                <InputGroup size="sm" className="mb-3">
                                    <FloatingLabel controlId="itemDescription" label="Item Description">
                                        <Form.Control ref={itemDescriptionRef} as="textarea" placeholder="Item Description" defaultValue={itemToEdit?.itemDescription || ""} style={{ resize: 'none' }} />
                                    </FloatingLabel>
                                </InputGroup>

                                <InputGroup size="sm" className="mb-3">
                                    <Form.Control
                                        ref={itemImageRef}
                                        onChange={uploadImageToStorage}
                                        type="file"
                                        id="itemImage"
                                        accept="image/png, image/jpeg" // Specify accepted file types if needed
                                    />
                                </InputGroup>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <button className='custom-button-second' onClick={() => setShowEditItemModal(false)}>
                                Close
                            </button> */}
                            <div className='edit-item-buttons'>
                                <button disabled={itemImageLoading} className='custom-button-third' onClick={() => {
                                    if (itemToEdit) removeItemFromStore(storeItems.indexOf(itemToEdit))
                                    setShowEditItemModal(false)
                                }}>
                                    Remove Item
                                </button>
                                <button disabled={itemImageLoading} className='custom-button' onClick={() => {
                                    editItemInStore()
                                    setShowEditItemModal(false)
                                }}>
                                    Save Changes
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>}
                </div>
            </>
        )
    }


    function MembershipsDisplay() {
        const columns: TableProps<StoreProps>['columns'] = [
            {
                title: 'Name',
                dataIndex: 'itemName',
                key: 'itemName',
            },
            {
                title: 'Price',
                dataIndex: 'itemPrice',
                key: 'itemPrice',
                render: (itemPrice) => `$${Number(itemPrice).toFixed(2)}`,
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record) => (
                    <button
                        className='custom-button-fourth'
                        onClick={() => addItemToCart(record)}
                    >
                        Add to Cart
                    </button>
                ),
            },
        ];

        return (
            <>
                {paymentPackages.length > 0 && (
                    <p>
                        Adjust your membership payment packages in your <a href="/settings#payment-settings">settings</a>.
                    </p>
                )}
                <div className="store-items">
                    {loading && <h2>Loading...</h2>}
                    {!loading && paymentPackages.length === 0 && (
                        <>
                            <h4>Payment packages not set up yet.</h4>
                            <p>
                                Set up your membership payment packages in your <a href="/settings#payment-settings">settings</a>.
                            </p>
                        </>
                    )}
                    {!loading && paymentPackages.length > 0 && (
                        <Table
                            columns={columns}
                            dataSource={paymentPackages.filter(item => item.enabled)}
                            rowKey={(record) => record.id} // Assuming each package has a unique id
                            pagination={{ pageSize: 6 }}
                        />
                    )}
                </div>
            </>
        );
    }

    function PaymentsDisplay() {
        const sortedSessions = stripeSessions.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        })
        const columns = [
            {
                title: 'Name',
                dataIndex: ['charges', 'data', 0, 'billing_details', 'name'],
                key: 'name',
                render: (name: string) => name || 'N/A',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (amount: number) => `$${(amount / 100).toFixed(2)}`,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_: any, record: {
                    charges: {
                        data: {
                            refunded: any;
                            paid: any;
                        }[];
                    };
                }) => {
                    const isRefunded = record.charges?.data[0]?.refunded;
                    const isPaid = record?.charges?.data[0]?.paid;

                    if (isRefunded) {
                        return <Tag color="red">REFUNDED</Tag>;
                    } else if (isPaid) {
                        return <Tag color="green">PAID</Tag>;
                    } else {
                        return <Tag color="blue">UNPAID</Tag>;
                    }
                },
            },
            {
                title: 'Date',
                dataIndex: 'created',
                key: 'created',
                render: (created: number) => new Date(created * 1000).toDateString(),
            },
            {
                title: 'Action',
                key: 'action',
                render: (_: any, record: any) => (
                    <button
                        onClick={() => {
                            setSelectedPayment(record);
                            setShowPaymentModal(true);
                        }}
                        className='custom-button-fourth'
                    >
                        View Details
                    </button>
                ),
            },
        ];
        const billingDetails = selectedPayment?.charges?.data[0]?.billing_details;

        const items: DescriptionsProps['items'] = [
            {
                key: 'name',
                label: 'Name',
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
                children: billingDetails?.name,
            },
            {
                key: 'email',
                label: 'Email',
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
                children: billingDetails?.email,
            },
            {
                key: 'address',
                label: 'Address',
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
                children: (
                    <>
                        {billingDetails?.address.line1} {billingDetails?.address.city} {billingDetails?.address.state} {billingDetails?.address.postal_code} {billingDetails?.address.country}
                    </>
                ),
            },
            {
                key: 'phone',
                label: 'Phone',
                children: billingDetails?.phone || 'n/a',
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },

            },
            {
                key: 'status',
                label: 'Status',
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
                children: (
                    <Badge
                        status={selectedPayment?.charges?.data[0]?.refunded ? 'error' : 'success'}
                        text={selectedPayment?.charges?.data[0]?.refunded ? 'Refunded' : 'Paid'}
                    />
                ),
            },

        ];

        const chargeItems = selectedPayment?.charges?.data.flatMap((charge: { amount: number; payment_method_details: { card: { brand: any; last4: any; }; }; status: any; created: number; receipt_url: string | undefined; }, index: number) => [
            {
                key: `charge-amount`,
                label: `Amount`,
                children: `$${(charge.amount / 100).toFixed(2)}`,
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
            },
            {
                key: `charge-card`,
                label: `Card`,
                children: `${charge.payment_method_details.card.brand} ${charge.payment_method_details.card.last4}`,
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },

            },
            {
                key: `charge-status`,
                label: `Status`,
                children: charge.status,
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
            },
            {
                key: `charge-date`,
                label: `Timestamp`,
                children: (
                    <>
                        <p>{new Date(charge.created * 1000).toLocaleString(undefined, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}</p>
                    </>
                ),
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },

            },
            {
                key: `charge-receipt`,
                label: `Receipt`,
                children: <div className='view-reciept'><a href={charge.receipt_url} target='_blank' rel='noopener noreferrer'>View Receipt<i className="bi bi-box-arrow-up-right"></i></a></div>,
                span: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3, xxl: 3 },
            },
        ]);


        return (
            <div className="payment-history-body">
                <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="payment-details">
                            {/* <h3>{selectedPayment?.charges?.data[0]?.billing_details.name}</h3>
                            <p style={{ fontWeight: "bold" }}>Email:</p>
                            <p>{selectedPayment?.charges?.data[0]?.billing_details.email}</p>
                            <p style={{ fontWeight: "bold" }}>Address:</p>
                            <p>{selectedPayment?.charges?.data[0]?.billing_details.address.line1}</p>
                            <p><span>         </span>{selectedPayment?.charges?.data[0]?.billing_details.address.city}, {selectedPayment?.charges?.data[0]?.billing_details.address.state} {selectedPayment?.charges?.data[0]?.billing_details.address.postal_code}</p>
                            <p><span>         </span>{selectedPayment?.charges?.data[0]?.billing_details.address.country}</p>
                            <p style={{ fontWeight: "bold" }}>Phone:</p>
                            <p>{selectedPayment?.charges?.data[0]?.billing_details.phone || "XXX-XXX-XXXX"}</p>
                            {selectedPayment?.charges?.data[0]?.refunded && <p>Refunded</p>}
                            {selectedPayment?.charges?.data?.map((charge: any, index: number) => {
                                return (
                                    <div key={index} className="charge-details">
                                        <p>${(charge.amount / 100).toFixed(2)}</p>
                                        <p>{charge.payment_method_details.card.brand} {charge.payment_method_details.card.last4}</p>
                                        <p>{charge.status}</p>
                                        <p>{new Date(charge.created * 1000).toDateString()}</p>
                                        <a href={charge.receipt_url} target='_blank'>View Recipt</a>
                                    </div>
                                )
                            })} */}
                            <Descriptions items={items} />
                            {chargeItems && chargeItems.map((item: DescriptionsItemType) => (
                                <Descriptions key={item.key} items={[item]} />
                            ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!selectedPayment?.charges?.data[0]?.refunded && (
                            <>
                                {showDangerRefundButton ? (
                                    <div className='refund-confirmation'>
                                        <button onClick={() => handleRefund(selectedPayment.id)} className='custom-button-third'>Confirm Refund</button>
                                        <button className='custom-button-second' onClick={() => setShowPaymentModal(false)}>Close</button>
                                    </div>
                                ) : (
                                    <Space>
                                        <button onClick={() => setShowDangerRefundButton(true)} className='custom-button-third'>Refund customer</button>
                                        <button className='custom-button-second' onClick={() => setShowPaymentModal(false)}>Close</button>
                                    </Space>
                                )}
                            </>
                        )}
                        {selectedPayment?.charges?.data[0]?.refunded && (
                            <button className='custom-button-second' onClick={() => setShowPaymentModal(false)}>Close</button>
                        )}
                    </Modal.Footer>
                </Modal>

                <button className='stripe-button' onClick={getStripeLoginLink}>
                    Stripe Dashboard
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stripe" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.226 5.385c-.584 0-.937.164-.937.593 0 .468.607.674 1.36.93 1.228.415 2.844.963 2.851 2.993C11.5 11.868 9.924 13 7.63 13a7.7 7.7 0 0 1-3.009-.626V9.758c.926.506 2.095.88 3.01.88.617 0 1.058-.165 1.058-.671 0-.518-.658-.755-1.453-1.041C6.026 8.49 4.5 7.94 4.5 6.11 4.5 4.165 5.988 3 8.226 3a7.3 7.3 0 0 1 2.734.505v2.583c-.838-.45-1.896-.703-2.734-.703" />
                    </svg>
                </button>
                <hr />
                {/* <div className="payment-history">
                    {sortedSessions.length > 0 && sortedSessions.map((session, index) => {
                        console.log(session);
                        if (session.status !== "succeeded") return null;
                        return (
                            <div key={index} className="payment-session-card" onClick={() => {
                                setSelectedPayment(session);
                                setShowPaymentModal(true);
                            }}>
                                <div className="left-section">
                                    <p>{new Date(session.created * 1000).toDateString()}</p>
                                    <p>${(session.amount / 100).toFixed(2)}</p>
                                </div>
                                <div className="right-section">
                                    <p>{session.charges?.data[0]?.refunded ? "REFUNDED" : session?.charges?.data[0]?.paid ? "PAID" : "UNPAID"}</p>
                                    <p>{session.charges?.data[0]?.billing_details.name}</p>
                                </div>
                            </div>
                        )
                    })}
                    {sortedSessions.length === 0 && <h2>No payment history available</h2>}
                </div> */}
                <div className="payment-history">
                    <Table
                        columns={columns}
                        // dataSource={sortedSessions} // Unfiltered sessions
                        dataSource={sortedSessions.filter(session => session.charges?.data[0]?.paid)} // Filter out sessions where paid is falsy
                        rowKey={(record) => record.id}
                    />
                </div>
            </div>
        )
    }

}