import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './styles.scss'
import MagfitLogo from '../../Images/newMagfitLogo.png'
import MagfitIcon from '../../Images/magfitIcon.png'
import { useStoreContext, defaultUserState } from '../../Utilities/globalState'
import { auth, storage } from '../../firebase-config'
import { getDownloadURL, ref } from '@firebase/storage'
import { signOut } from '@firebase/auth'
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { functions } from '../../firebase-config'
import { updateMoregymsPremium } from '../../Utilities/helpers'
import Notifications from './Notifications'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const Nav = () => {
  const [state, dispatch] = useStoreContext()
  const [gymLogoURL, setGymLogoURL] = useState(
    state.currentUser.gymLogoURL || null,
  )
  const [gymId] = useState(
    state.currentUser.gymId,
  )
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const linkToCopy = `https://www.moregyms.com/gym-page/${gymId}`;

    // Create a temporary input element to copy the text
    const tempInput = document.createElement('input');
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    // Set the "copied" state to true
    setCopied(true);

    // Reset the "copied" state after a delay
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Reset to false after 2 seconds
  };
  const navigate = useNavigate()

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear()
      window.location.reload()
    })
  }
  const setCollapse = (setting: number) => {
    // collapse is set to 1 initially to avoid animation on initial page load
    // navigation is collapsed w/ animation when collapse = 2
    // navigation is open w/ animation when collapse = 3

    dispatch({
      type: 'COLLAPSE_NAV',
      navCollapse: setting,
    })
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 700) {
        dispatch({
          type: 'COLLAPSE_NAV',
          navCollapse: 1,
        })
      }
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const logoRef = ref(storage, `LogoImages/${state.currentUser.gymId}`)
    getDownloadURL(logoRef)
      .then((url) => {
        setGymLogoURL(url)
      })
      .catch((err) => console.log('No user logo found'))
  }, [])

  useEffect(() => {
    if (state.navCollapse === 3) {
      setTimeout(() => {
        dispatch({
          type: 'COLLAPSE_NAV',
          navCollapse: 1,
        })
      }, 1000)
    }
    if (state.navCollapse === 2) {
      setTimeout(() => {
        dispatch({
          type: 'COLLAPSE_NAV',
          navCollapse: 4,
        })
      }, 1000)
    }
  }, [state.navCollapse])


  useEffect(() => {
    if (state.currentUser.stripeData && state.currentUser.stripeData.id) {
      const getStripeAccount: HttpsCallable = httpsCallable(functions, 'updateStripeAccount')
      getStripeAccount({ accountID: state.currentUser.stripeData.id })
        .then((response: any) => {
          const { data } = response;
          dispatch({
            type: 'SET_CURRENT_USER',
            currentUser: {
              ...state.currentUser,
              stripeData: data,
            },
          })
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (state.currentUser?.moreGymsPremium?.id) {
      (async () => {
        const subscription = await updateMoregymsPremium(state.currentUser.moreGymsPremium.id)
        dispatch({
          type: 'SET_CURRENT_USER',
          currentUser: {
            ...state.currentUser,
            moreGymsPremium: subscription,
          },
        })
      })();
    }

  }, [])

  auth.onAuthStateChanged((user) => {
    if (!user) {
      signUserOut()
    }
  })



  return (
    <div
      className={
        'navigation-container ' +
        (state.navCollapse === 3
          ? 'navigation-open'
          : (state.navCollapse === 2 || state.navCollapse === 4) &&
          'navigation-close')
      }
    >
      {/* <Notifications /> */}

      {(state.navCollapse === 1 || state.navCollapse === 3) && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tour-tooltip">Close</Tooltip>}
        >
          <i
            onClick={() => setCollapse(2)}
            className="bi bi-chevron-double-left navigation-collapse-icon"
          />
        </OverlayTrigger>
      )}
      {(state.navCollapse === 2 || state.navCollapse === 4) && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="tour-tooltip">Open</Tooltip>}
        >
          <i
            onClick={() => setCollapse(3)}
            className="bi bi-chevron-double-right navigation-collapse-icon"
          />
        </OverlayTrigger>
      )}
      <div className="navigation-head">
        <div className="navigation-user-logo-container">
          {gymLogoURL && <img className="gym-logo" src={gymLogoURL} alt="" />}
        </div>
        <div className="navigation-username">
          <h3>{state.currentUser.gymName}</h3>
        </div>
      </div>
      <div
        className="navigation-navigation-container"
      >
        <div className='main-links'>
          <Link to="/home">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/home' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-house ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'homeHover')
                }
              ></i>
              <label>Home</label>
            </div>
          </Link>

          <Link to="/info">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/info' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-info-square ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'gymInfoHover')
                }
              ></i>
              <label>Gym info</label>
            </div>
          </Link>
          <Link to="/schedule">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/schedule' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-calendar3 ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'scheduleHover')
                }
              ></i>{' '}
              <label>Class schedule</label>
            </div>
          </Link>
          <Link to="/media">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/media' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-images ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'mediaHover')
                }
              ></i>{' '}
              <label>Media</label>
            </div>
          </Link>
          <Link to="/reviews">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/reviews' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-star ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'communityHover')
                }
              ></i>{' '}
              <label>Reviews</label>
            </div>
          </Link>
          <Link to="/members">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/members' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-list-stars ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'membersHover')
                }
              ></i>
              <label>Trainers</label>
            </div>
          </Link>
          {state.currentUser.stripeData && state.currentUser.stripeData.id && (
            <Link to="/store">
              <div
                className={
                  'navigation-link ' +
                  (window.location.pathname === '/store' && 'selected-link')
                }
              >
                <i
                  className={
                    'bi bi-shop-window ' +
                    ((state.navCollapse === 2 || state.navCollapse === 4) &&
                      'storeHover')
                  }
                ></i>
                <label>Store</label>
              </div>
            </Link>
          )}
        </div>
        <div className='footer-links'>
          <a href={`https://www.moregyms.com/gym-page/${gymId}`} target="_blank">
            <div
              className={
                'navigation-link '
              }
            >
              <i className={'bi bi-box-arrow-up-right'}></i>
              <label>View your live page</label>
            </div>
          </a>
          <div
            className={
              'navigation-link '
            }
            onClick={handleCopyToClipboard}
          >
            <i className="bi bi-link-45deg"></i>
            <label>{copied ? 'Copied' : 'Copy page link'}</label>
          </div>
          <Link to="/settings">
            <div
              className={
                'navigation-link ' +
                (window.location.pathname === '/settings' && 'selected-link')
              }
            >
              <i
                className={
                  'bi bi-gear ' +
                  ((state.navCollapse === 2 || state.navCollapse === 4) &&
                    'settingsHover')
                }
              ></i>
              <label>Settings</label>
            </div>
          </Link>
          <p>©MagFit Inc. 2024 DBA MoreGyms</p>
        </div>
      </div>
    </div>
  )
}
