import React, { useEffect, useRef, useState } from 'react'
import "./styles.scss"
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { functions } from '../../../../../firebase-config'
import { useStoreContext } from '../../../../../Utilities/globalState'
import { updateMoregymsPremium, reportNonBlockingError } from "../../../../../Utilities/helpers"
import Spinner from '../../../../Utilities/Spinner'
import { Modal } from 'react-bootstrap'

export default function PremiumSubscription() {
    const [state, dispatch] = useStoreContext();
    const [price, setPrice] = useState(69);
    const [subscriptionSessionStatus, setSubscriptionSessionStatus] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    // subscription variables
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const trialEndDate = state.currentUser?.moreGymsPremium?.trial_end;
    const daysLeftOfTrial = trialEndDate ? Math.floor((new Date(trialEndDate * 1000).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : null;
    const canceledSubscription = state.currentUser?.moreGymsPremium?.cancel_at_period_end;
    const canceledSubscriptionEndDate = state.currentUser?.moreGymsPremium?.cancel_at;
    const canceledSubscriptionDate = canceledSubscriptionEndDate > 0 ? new Date(canceledSubscriptionEndDate * 1000) : null;
    const subscriptionStatus = state.currentUser?.moreGymsPremium?.status;

    function getPremiumPrice() {
        const getPremiumPrice: HttpsCallable = httpsCallable(functions, 'checkSubscriptionPrice');
        getPremiumPrice()
            .then((result: any) => {
                const updatedPrice: number = result?.data?.unit_amount || 6900;
                setPrice(updatedPrice / 100);
            }).catch((error) => {
                reportNonBlockingError(error, state, 'PremiumSubscription -> getPremiumPrice');
            });
    }

    function handleCreateSubscription(promo = false) {
        setLoading(true);
        if (subscriptionSessionStatus?.url) {
            window.location = subscriptionSessionStatus.url;
            return;
        }
        let createSubscription: HttpsCallable;
        if (promo) createSubscription = httpsCallable(functions, 'createPromotionalSubscriptionSession');
        else createSubscription = httpsCallable(functions, 'createSubscriptionSession');
        createSubscription()
            .then((result: any) => {
                const { url, id } = result?.data;
                localStorage.setItem('subscriptionSessionID', id);
                window.location = url;
            })
            .catch((error) => {
                reportNonBlockingError(error, state, 'handleCreateSubscription');
            });
        setLoading(false);
    }

    function updatePremiumSubscriptionState(data: any) {
        dispatch({
            type: 'SET_CURRENT_USER',
            currentUser: {
                ...state.currentUser,
                moreGymsPremium: data
            },
        })
    }

    function handleCancelSubscription() {
        setLoading(true);
        if (state.currentUser?.moreGymsPremium?.id) {
            const cancelSubscription: HttpsCallable = httpsCallable(functions, 'cancelSubscription');
            cancelSubscription({ subscriptionID: state.currentUser.moreGymsPremium.id })
                .then(async (result: any) => {
                    // console.log('subscription canceled', result);
                    const subscription = await updateMoregymsPremium(result.data.id)
                    if (subscription) {
                        updatePremiumSubscriptionState(subscription);
                    }
                    setShowCancelModal(false);
                }
                ).catch((error) => {
                    reportNonBlockingError(error, state, 'handleCancelSubscription');
                });
        } else {
            console.log('no subscription id');
        }
        setLoading(false);
    }

    function handleResumeSubscription() {
        setLoading(true);
        if (state.currentUser?.moreGymsPremium?.id) {
            const resumeSubscription: HttpsCallable = httpsCallable(functions, 'resumeSubscription');
            const resumableSubscription = canceledSubscriptionEndDate > Date.now() / 1000;
            if (!resumableSubscription) {
                console.log('subscription not resumable');
                handleCreateSubscription()
            } else {
                console.log('subscription resumable');
                resumeSubscription({ subscriptionID: state.currentUser.moreGymsPremium.id })
                    .then(async (result: any) => {
                        console.log('subscription resumed', result);
                        const { url, id } = result?.data;
                        localStorage.setItem('subscriptionSessionID', id);
                        const subscription = await updateMoregymsPremium(result.data.id)
                        if (subscription) {
                            updatePremiumSubscriptionState(subscription);
                        }
                        setShowModal(false);
                    }
                    ).catch((error) => {
                        reportNonBlockingError(error, state, 'handleResumeSubscription');
                    });
            }
        } else {
            console.log('no subscription id');
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!state.currentUser.moreGymsPremium) {
            const sessionID = localStorage.getItem('subscriptionSessionID');
            if (sessionID) {
                // console.log('sessionID', sessionID);
                const retrieveSubscription: HttpsCallable = httpsCallable(functions, 'checkSubscriptionSessionStatus');
                retrieveSubscription({ sessionID })
                    .then(async (result: any) => {
                        setSubscriptionSessionStatus(result?.data);
                        if (result?.data?.subscription) {
                            const subscription = await updateMoregymsPremium(result.data.subscription)
                            if (subscription) {
                                updatePremiumSubscriptionState(subscription);
                            }
                        }
                    })
                    .catch((error) => {
                        reportNonBlockingError(error, state, 'PremiumSubscription -> useEffect -> retrieveSubscription');
                        localStorage.removeItem('subscriptionSessionID');
                    });
            }
        }
    }, []);

    useEffect(() => {
        getPremiumPrice();
    }, []);

    // subscription status possible values: incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid, or paused
    if (state.currentUser?.moreGymsPremium?.status === 'active'
        || state.currentUser?.moreGymsPremium?.status === 'trialing'
    ) return <ActiveSubscription />
    if (state.currentUser?.moreGymsPremium?.status === 'canceled' ||
        state.currentUser?.moreGymsPremium?.status === 'unpaid' ||
        state.currentUser?.moreGymsPremium?.status === 'incomplete' ||
        state.currentUser?.moreGymsPremium?.status === 'incomplete_expired' ||
        state.currentUser?.moreGymsPremium?.status === 'past_due' ||
        state.currentUser?.moreGymsPremium?.status === 'paused'
    ) return <ExpiredSubscription />

    return (
        <header className="premium-container">
            <div className='premium-heading'>
                <h2>Pro</h2>
                <p>Best for gyms who are prioritizing growth</p>
            </div>
            <div className='premium-price'>
                <h1>${price.toFixed(2)}</h1>
                <p>/mo</p>

            </div>
            <div className='premium-cta'>
                <button disabled={loading} onClick={() => handleCreateSubscription(true)} className='custom-button'>{loading && <Spinner />}Try 1 month free</button>
                {/* <p>Free for 1 month, then ${price.toFixed(2)} per month after. Offer only available if you haven't tried Premium before. Terms apply.</p> */}

            </div>
        </header>
    )

    function ExpiredSubscription() {
        return (
            <header className="premium-container">
                <section>
                    <h2>Reenroll Today</h2>
                    <h2>with <span className='cta-span'>MoreGyms Premium</span></h2>
                </section>
                <section>
                    {/* <p>Only <span className='underline-span'>${price.toFixed(2)}/month</span>. </p> */}
                    <button disabled={loading} onClick={() => handleCreateSubscription(false)} className='custom-button'>{loading && <Spinner />}Sign Up</button>
                    <small>Only ${price.toFixed(2)} per month. Cancel anytime. <span className='underline-span'>Terms apply.</span></small>
                </section>
            </header>
        )
    }

    function ActiveSubscription() {
        return (
            <>
                <header className="premium-container">
                    <div className='premium-heading'>
                        <h2>Pro</h2>
                        <p>Best for gyms who are prioritizing growth</p>
                    </div>
                    <div className='premium-price'>
                        <h1>${price.toFixed(2)}</h1>
                        <p>/mo</p>

                    </div>
                    <div className='premium-cta'>
                        <button disabled={true} onClick={() => setShowModal(true)} className='custom-button'>Current Plan</button>
                    </div>
                    <p className='update-subscription'>To make changes to your MoreGyms Premium subscription <button className='custom-button-hyperlink' onClick={() => setShowModal(true)} >here</button>.</p>
                </header>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>MoreGyms Premium</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Your subscription is active</p>
                        {canceledSubscriptionDate &&
                            <>
                                <p>Your subscription will end on {canceledSubscriptionDate.toLocaleDateString()}</p>
                                <p>To keep your MoreGyms Premium subscription and maintain your access to the premium features, resume your subscription today.</p>
                            </>
                        }
                        {!canceledSubscriptionDate && trialEndDate &&
                            <>
                                <p>Your free trial ends in {daysLeftOfTrial} days</p>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {canceledSubscriptionDate ?
                            <button disabled={loading} onClick={() => handleResumeSubscription()} className='custom-button'>{loading && <Spinner />}Resume Subscription</button>
                            :
                            <button disabled={loading} onClick={() => {
                                setShowCancelModal(true)
                                setShowModal(false)
                            }} className='custom-button'>Cancel Subscription</button>}
                        <button disabled={loading} onClick={() => setShowModal(false)} className='custom-button'>Close</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            MoreGyms Premium
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>By canceling your subscription, at the end of your billing cycle you will lose access to all premium features. You can sign up again at any time.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button disabled={loading} onClick={handleCancelSubscription} className='custom-button-third'>{loading && <Spinner />}Cancel Subscription</button>
                        <button disabled={loading} onClick={() => setShowCancelModal(false)} className='custom-button'>Close</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
