import { Timestamp } from "@firebase/firestore";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config";
import { GeoPoint } from "@firebase/firestore";
import { httpsCallable, HttpsCallable } from "firebase/functions";
import { functions } from "../firebase-config";

const firebaseTimestampConversion = (data: any) => {
  for (let i = 0; i < 7; i++) {
    let [openH, openM] = data[i]["open"].split(":").map((a: any) => Number(a));
    let [closeH, closeM] = data[i]["close"]
      .split(":")
      .map((a: any) => Number(a));
    let openTimestamp = Timestamp.fromDate(
      new Date(2022, 9, 17 + i, openH, openM)
    );
    let closeTimestamp = Timestamp.fromDate(
      new Date(2022, 9, 17 + i, closeH, closeM)
    );
    data[i]["openTimestamp"] = openTimestamp;
    data[i]["closeTimestamp"] = closeTimestamp;
  }
};

export const parseDataForState = ([id, data]: [string, any]) => {
  const currentUser = {
    moreGymsPremium: data["moreGymsPremium"] || null,
    stripeData: data["stripeData"] || null,
    paymentPackages: data["PaymentPackages"] || [],
    videoUrl: data["WalkthroughURL"] || "",
    additionalPhotos: data["Additional Photos"] || [],
    dayPassLink: data["DayPassLink"] || [],
    instagram: data["Instagram"] || "",
    longitude: data["Longitude"] || 0,
    latitude: data["Latitude"] || 0,
    location: data["Location"] || {},
    bio: data["Bio"] || "",
    claimedGym: data["Claimed Gym"] || false,
    classList: data["Class List"] || [],
    localUsers: data["LocalUsers"] || [],
    gymType: data["GymType"] || [],
    gymId: id,
    gymOwnerId: data["Admin ID"],
    gymName: data["Name"],
    geoPoint: data["Location"] || {},
    geohash: data["geohash"] || "",
    email: data["Admin Email"],
    phone: data["Phone Number"],
    passKey: data["Admin Key"],
    address: data["Address"],
    zipCode: data["Zipcode"],
    stateInUs: data["State"],
    phoneNumber: data["Phone Number"],
    website: data["Website"],
    appointmentOnly: data["Appointment Only"],
    open247: data["24 Hours"],
    openHours: data["Hours Of Operation"],
    gymLogoURL: data["Logo URL"],
    gymHeaderURL: data["Header URL"],
    legal: {
      releaseWaiver: data["Legal"]?.["ReleaseWaiver"] || "",
      privacyPolicy: data["Legal"]?.["PrivacyPolicy"] || "",
      terms: data["Legal"]?.["Terms"] || "",
      refundPolicy: data["Legal"]?.["RefundPolicy"] || "",
    },
    amenities: {
      lockers: data["Amenities"]["LockerRooms"] || false,
      sauna: data["Amenities"]["Sauna"] || false,
      steam: data["Amenities"]["StreamRoom"] || false,
      nutritionBar: data["Amenities"]["NutritionBar"] || false,
      nutritionCoaching: data["Amenities"]["NutritionCoaching"] || false,
      water: data["Amenities"]["WaterStation"] || false,
      towels: data["Amenities"]["Towels"] || false,
      showers: data["Amenities"]["Showers"] || false,
      bathrooms: data["Amenities"]["Bathrooms"] || false,
      wifi: data["Amenities"]["FreeWifi"] || false,
      freeWifi: data["Amenities"]["FreeWifi"] || false,
      parking: data["Amenities"]["Parking"] || false,
      lounge: data["Amenities"]["LoungeArea"] || false,
      kidArea: data["Amenities"]["KidArea"] || false,
      weightPlatforms: data["Amenities"]["WeightliftingPlatform"] || false,
      dogFriendly: data["Amenities"]["Dog Friendly"] || false,
      music: data["Amenities"]["Music"] || false,
      tvs: data["Amenities"]["TVs"] || false,
    },
    services: data["Services"] || {
      dropInPrice: data["Drop in Fee"],
      trainersAvailable: data["Trainers Available"] || false,
      wifiPW: data["Wifi Password"],
      wifiAvailable: data["Free Wi-Fi"] || false,
      dayPass: data["Offers Day Pass"] || false,
      openGym: data["Open Gym"] || false,
      openGymInfo: data["Open Gym Hours"],
      nutritionCoaching: data["Nutrition Coaching"] || false,
      personalFilming: data["Filming Approved"] || false,
      classesOffered: data["Classes"] || false,
      classesOfferedInfo: data["Class Hours"],
      weightPlatforms: data["Weightlifting Platforms"] || false,
      parking: data["Parking"] || false,
      parkingInfo: data["Parking Info"],
      dogFriendly: data["Dog Friendly"] || false,
    },
    equipment: data["Equipment"] || {
      SkiErg: false,
      WrestlingMats: false,
      CalfRaiseMachine: false,
      HackSquat: false,
      Rigs: false,
      FocusMittsAndTaiPads: false,
      KettleBells: false,
      ChalkStations: false,
      Sandbags: false,
      JumpRopes: false,
      RowingMachines: false,
      DoubleEndBags: false,
      OlympicBarbells: false,
      TricepDipBars: false,
      SpeedBags: false,
      BarbellClips: false,
      KneePadsAndElbowPads: false,
      PlyometricBoxes: false,
      ResistanceBands: false,
      StairMaster: false,
      KiloPlates: false,
      AgilityLaddersAndCones: false,
      Dumbells: false,
      CageOrOctagon: false,
      TRXBand: false,
      TurfSection: false,
      LegPressMachine: false,
      Mirrors: false,
      GymnasticRings: false,
      Headgear: false,
      MedicineBalls: false,
      PVCPipes: false,
      BattleRopes: false,
      DeclineBench: false,
      CableMachines: false,
      RopeClimb: false,
      BoxingRing: false,
      HamstringCurlMachine: false,
      Elliptical: false,
      SparringGlovesAndMitts: false,
      GroinProtectors: false,
      BumperPlates: false,
      FoamRollers: false,
      HeavyBags: false,
      MMAGloves: false,
      Treadmill: false,
      SubmissionGrapplingDummies: false,
      LegExtensionMachine: false,
      Sleds: false,
      AbMats: false,
      SquatRacks: false,
      SmithMachine: false,
      ShinGuardsAndInstepGuards: false,
      GHDMachine: false,
      PullUpBars: false,
      JudoMats: false,
      WallClimbingHolds: false,
      C2Bike: false,
      MetalPlates: false,
      AssaultBikes: false,
      FlatInclineBench: false,
      PegBoard: false,
    },
  };
  return currentUser;
};

export const parseDataForDB = (data: any) => {
  firebaseTimestampConversion(data.currentUser.openHours);
  return {
    moreGymsPremium: data.currentUser.moreGymsPremium || null,
    stripeData: data.currentUser.stripeData || null,
    PaymentPackages: data.currentUser.paymentPackages || [],
    WalkthroughURL: data.currentUser.videoUrl || "",
    DayPassLink: data.currentUser.dayPassLink || [],
    GymType: data.currentUser.gymType || ["Health Club"],
    // "GeoPoint": data.currentUser.geoPoint || {},
    "Claimed Gym": data.currentUser.claimedGym || false,
    LocalUsers: data.currentUser.localUsers || [],
    "Class List": data.currentUser.classList || [],
    Bathrooms: data.currentUser.amenities.bathrooms || false,
    Parking: data.currentUser.services.parking || false,
    Name: data.currentUser.gymName,
    "Dog Friendly": data.currentUser.services.dogFriendly || false,
    "Wifi Password": data.currentUser.services.wifiPW,
    "Class Hours": data.currentUser.services.classesOfferedInfo,
    "Drop in Fee": String(data.currentUser.services.dropInPrice),
    "Open Gym": data.currentUser.services.openGym || false,
    "Trainer #1": "",
    $: false,
    "Phone Number": data.currentUser.phoneNumber,
    "Towel Service": data.currentUser.amenities.towels || false,
    "Admin Email": data.currentUser.email,
    "Admin ID": data.currentUser.gymOwnerId,
    "24 Hours": data.currentUser.open247 || false,
    "Nutrition Bar": data.currentUser.amenities.nutrition || false,
    Zipcode: data.currentUser.zipCode,
    Showers: data.currentUser.amenities.showers || false,
    $$$: false,
    "Trainer #4": "",
    "Trainers Available": data.currentUser.services.trainersAvailable || false,
    Classes: data.currentUser.services.classesOffered || false,
    Website: data.currentUser.website,
    "Trainer #2": "",
    "Offers Day Pass": data.currentUser.services.dayPass || false,
    "Open Gym Hours": data.currentUser.services.openGymInfo,
    "Weightlifting Platform":
      data.currentUser.services.weightPlatforms || false,
    Sauana: data.currentUser.amenities.sauna || false,
    "Filming Approved": data.currentUser.services.personalFilming || false,
    "Nutrition Coaching":
      data.currentUser.services.nutritionCoaching || false || false,
    "Appointment Only": data.currentUser.appointmentOnly || false,
    "Admin Password": "",
    "Hours Of Operation": data?.currentUser?.openHours || {},
    "Parking Info": data.currentUser.services.parkingInfo,
    "Free Wi-Fi": data.currentUser.services.wifiAvailable || false,
    "Admin Key": data.currentUser.passKey,
    "Steam Room": data.currentUser.amenities.steam || false,
    Address: data.currentUser.address,
    State: data.currentUser.stateInUs,
    $$: true,
    "Locker Rooms": data.currentUser.amenities.lockers || false,
    "Trainer #3": "",
    "Water Station": data.currentUser.amenities.water || false,
    "Logo URL": data.currentUser.gymLogoURL,
    "Header URL": data.currentUser.gymHeaderURL,
    Legal:{
      ReleaseWaiver: data?.currentUser?.legal?.releaseWaiver || "",
      PrivacyPolicy: data?.currentUser?.legal?.privacyPolicy || "",
      Terms: data?.currentUser?.legal?.terms || "",
      RefundPolicy: data?.currentUser?.legal?.refundPolicy || "",
    },
    Amenities: {
      LockerRooms: data.currentUser.amenities.lockers || false,
      Sauna: data.currentUser.amenities.sauna || false,
      SteamRoom: data.currentUser.amenities.steam || false,
      StreamRoom: data.currentUser.amenities.steam || false,
      NutritionBar: data.currentUser.amenities.nutritionBar || false,
      NutritionCoaching: data.currentUser.amenities.nutritionCoaching || false,
      WaterStation: data.currentUser.amenities.water || false,
      Towels: data.currentUser.amenities.towels || false,
      Showers: data.currentUser.amenities.showers || false,
      Bathrooms: data.currentUser.amenities.bathrooms || false,
      LoungeArea: data.currentUser.amenities.lounge || false,
      TVs: data.currentUser.amenities.tvs || false,
      Music: data.currentUser.amenities.music || false,
      FreeWifi: data.currentUser.amenities.wifi || false,
      Parking: data.currentUser.amenities.parking || false,
      KidArea: data.currentUser.amenities.kidArea || false,
      WeightliftingPlatform:
        data.currentUser.amenities.weightPlatforms || false,
      DogFriendly: data.currentUser.amenities.dogFriendly || false,
    },
    Equipment: data.currentUser.equipment || {
      SkiErg: false,
      WrestlingMats: false,
      CalfRaiseMachine: false,
      HackSquat: false,
      Rigs: false,
      FocusMittsAndTaiPads: false,
      KettleBells: false,
      ChalkStations: false,
      Sandbags: false,
      JumpRopes: false,
      RowingMachines: false,
      DoubleEndBags: false,
      OlympicBarbells: false,
      TricepDipBars: false,
      SpeedBags: false,
      BarbellClips: false,
      KneePadsAndElbowPads: false,
      PlyometricBoxes: false,
      ResistanceBands: false,
      StairMaster: false,
      KiloPlates: false,
      AgilityLaddersAndCones: false,
      Dumbells: false,
      CageOrOctagon: false,
      TRXBand: false,
      TurfSection: false,
      LegPressMachine: false,
      Mirrors: false,
      GymnasticRings: false,
      Headgear: false,
      MedicineBalls: false,
      PVCPipes: false,
      BattleRopes: false,
      DeclineBench: false,
      CableMachines: false,
      RopeClimb: false,
      BoxingRing: false,
      HamstringCurlMachine: false,
      Elliptical: false,
      SparringGlovesAndMitts: false,
      GroinProtectors: false,
      BumperPlates: false,
      FoamRollers: false,
      HeavyBags: false,
      MMAGloves: false,
      Treadmill: false,
      SubmissionGrapplingDummies: false,
      LegExtensionMachine: false,
      Sleds: false,
      AbMats: false,
      SquatRacks: false,
      SmithMachine: false,
      ShinGuardsAndInstepGuards: false,
      GHDMachine: false,
      PullUpBars: false,
      JudoMats: false,
      WallClimbingHolds: false,
      C2Bike: false,
      MetalPlates: false,
      AssaultBikes: false,
      FlatInclineBench: false,
      PegBoard: false,
    },
    "Additional Photos": data.currentUser.additionalPhotos || [],
    Instagram: data.currentUser.instagramUsername || "",
    Bio: data.currentUser.gymBio || "",
    Longitude: data.currentUser.geoPoint.longitude || 0,
    Latitude: data.currentUser.geoPoint.latitude || 0,
    Location:
      data.currentUser.geoPoint.latitude && data.currentUser.geoPoint.longitude
        ? new GeoPoint(
            data.currentUser.geoPoint.latitude,
            data.currentUser.geoPoint.longitude
          )
        : new GeoPoint(0, 0),
    geohash: data.currentUser.geohash || "",
    id: data.currentUser.gymId,
  };
};

export const updateMemberClassAttendance = async (unfililteredClasses: any) => {
  const classes = [...unfililteredClasses];
  const now = Date.now();
  for (let i = classes.length - 1; i >= 0; i--) {
    const session = classes[i];
    if (session.completed) break;
    let sessionStart = session.classDate.seconds * 1000;
    if (sessionStart < now) {
      addClassToUsers(session.attendees, session.id);
      session.completed = true;
      const classRef = doc(db, "AllClasses", session.id);
      setDoc(classRef, session, {
        merge: true,
      });
    }
  }
};

export const parseAmenityKeysForDisplay = (key: string) => {
  // due to my lack of foresight and probable laziness, this function is for turning the strings into more descriptive objects with amenity info
  switch (key) {
    case "lockers":
      return {
        name: "Locker Room",
        info: "A secure place for members to put their belongings.",
      };
    case "sauna":
      return {
        name: "Sauna",
        info: "A dry room heated to between 150°-210°F / 70°-100°C.",
      };
    case "steam":
      return {
        name: "Steam Room",
        info: "Heated room created when a water-filled generator pumps steam into it.",
      };
    case "nutrition":
      return {
        name: "Fuel Station",
        info: "Offers protein drinks, energy drinks, pre-workout, or anything available for consumption.",
      };
    case "water":
      return {
        name: "Water Station",
        info: "Offers purified water for consumption.",
      };
    case "towels":
      return {
        name: "Towel Service",
        info: "Clean towels available for use.",
      };
    case "showers":
      return {
        name: "Showers",
        info: "An enclosed private room for bathing.",
      };
    case "bathrooms":
      return {
        name: "Bathrooms",
        info: "A private room for members to dispose of waste and wash hands.",
      };
    case "trainersAvailable":
      return {
        name: "Trainers Available",
        info: "Trainers on site for questions regarding equipment or movements.",
      };
    case "wifi":
      return {
        name: "Free Wifi",
        info: "Offers free WIFI for members.",
      };
    case "dayPass":
      return {
        name: "Day Pass",
        info: "Drop-ins welcomed!",
      };
    case "openGym":
      return {
        name: "Open Gym",
        info: "Gym open to the public for use outside class times.",
      };
    case "nutritionCoaching":
      return {
        name: "Nutritional Coaching",
        info: "Coaching that teaches clients about the principles of nutrition science.",
      };
    case "filming":
      return {
        name: "Personal Filming Allowed",
        info: "Personal cameras are allowed for filming.",
      };
    case "classes":
      return {
        name: "Classes Offered",
        info: "Guided fitness classes available to members and drop-ins.",
      };
    case "parking":
      return {
        name: "Parking Available",
        info: "A place for members to park their vehicle while using the facility.",
      };
    case "dogFriendly":
      return {
        name: "Dog Friendly",
        info: "All dog breeds allowed on site.",
      };
    case "lounge":
      return {
        name: "Lounge Area",
        info: "A place for members to relax and socialize.",
      };
    case "weightPlatforms":
      return {
        name: "Weightlifting Platforms",
        info: "A protected area to drop heavily loaded barbells to the ground.",
      };
    case "tvs":
      return {
        name: "TVs",
        info: "Televisions available for members to watch.",
      };
    case "music":
      return {
        name: "Music",
        info: "Music played throughout the facility.",
      };
    case "kidArea":
      return {
        name: "Kid Area",
        info: "A place for children to play.",
      };
    case "nutritionBar":
      return {
        name: "Nutrition Bar",
        info: "Offers protein drinks, energy drinks, pre-workout, or anything available for consumption.",
      };
    case "towells":
      return {
        name: "Towels",
        info: "Clean towels available for use.",
      };

    default:
      return {
        name: key,
        info: "something went wrong",
      };
  }
};

const addClassToUsers = async (users: any, classID: any) => {
  for (const user of users) {
    user.classesAttended.push(classID);
    const userRef = doc(db, "AllGymsMembers", user.id);
    setDoc(userRef, user, {
      merge: true,
    });
  }
};

export function parseEquipmentKeysToStrings(key: string): string {
  const equipment = {
    SkiErg: "Ski Erg",
    WrestlingMats: "Wrestling Mats",
    CalfRaiseMachine: "Calf Raise Machine",
    HackSquat: "Hack Squat",
    Rigs: "Rigs",
    FocusMittsAndTaiPads: "Focus Mitts And Tai Pads",
    KettleBells: "Kettle Bells",
    ChalkStations: "Chalk Stations",
    Sandbags: "Sandbags",
    JumpRopes: "Jump Ropes",
    RowingMachines: "Rowing Machines",
    DoubleEndBags: "Double End Bags",
    OlympicBarbells: "Olympic Barbells",
    TricepDipBars: "Tricep Dip Bars",
    SpeedBags: "Speed Bags",
    BarbellClips: "Barbell Clips",
    KneePadsAndElbowPads: "Knee Pads And Elbow Pads",
    PlyometricBoxes: "Plyometric Boxes",
    ResistanceBands: "Resistance Bands",
    StairMaster: "Stair Master",
    KiloPlates: "Kilo Plates",
    AgilityLaddersAndCones: "Agility Ladders And Cones",
    Dumbells: "Dumbells",
    CageOrOctagon: "Cage Or Octagon",
    TRXBand: "TRX Band",
    TurfSection: "Turf Section",
    LegPressMachine: "Leg Press Machine",
    Mirrors: "Mirrors",
    GymnasticRings: "Gymnastic Rings",
    Headgear: "Headgear",
    MedicineBalls: "Medicine Balls",
    PVCPipes: "PVC Pipes",
    BattleRopes: "Battle Ropes",
    DeclineBench: "Decline Bench",
    CableMachines: "Cable Machines",
    RopeClimb: "Rope Climb",
    BoxingRing: "Boxing Ring",
    HamstringCurlMachine: "Hamstring Curl Machine",
    Elliptical: "Elliptical",
    SparringGlovesAndMitts: "Sparring Gloves And Mitts",
    GroinProtectors: "Groin Protectors",
    BumperPlates: "Bumper Plates",
    FoamRollers: "Foam Rollers",
    HeavyBags: "Heavy Bags",
    MMAGloves: "MMA Gloves",
    Treadmill: "Treadmill",
    SubmissionGrapplingDummies: "Submission Grappling Dummies",
    LegExtensionMachine: "Leg Extension Machine",
    Sleds: "Sleds",
    AbMats: "Ab Mats",
    SquatRacks: "Squat Racks",
    SmithMachine: "Smith Machine",
    ShinGuardsAndInstepGuards: "Shin Guards And Instep Guards",
    GHDMachine: "GHD Machine",
    PullUpBars: "Pull Up Bars",
    JudoMats: "Judo Mats",
    WallClimbingHolds: "Wall Climbing Holds",
    C2Bike: "C2 Bike",
    MetalPlates: "Metal Plates",
    AssaultBikes: "Assault Bikes",
    FlatInclineBench: "Flat Incline Bench",
    PegBoard: "Peg Board",
  };
  return equipment[key as keyof typeof equipment] || key;
}

export async function parseDataForGraph(fbData: any, platform: any) {
  const data: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let point of fbData["taps"]) {
    if (point["device"][platform]) {
      const month = new Date(point["createdAt"].seconds * 1000).getMonth();
      data[month] += 1;
    }
  }
  let month = new Date().getMonth();
  while (month < 11) {
    data.unshift(data.pop() as number);
    month++;
  }
  return data;
}

export function updateMoregymsPremium(subscriptionID: string): any {
  const retrieveSubscription = httpsCallable(functions, "retrieveSubscription");
  return retrieveSubscription({ subscriptionID: subscriptionID })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      reportNonBlockingError(error, null, "helpers -> updateMoregymsPremium -> retrieveSubscription");
      return null;
    });
  // return null;
}

export async function reportNonBlockingError(
  error: Error,
  state: any,
  customMessage: string = "No custom message"
) {
  const ErrorRef = collection(db, "DashboardNonBlockingErrors");
  addDoc(ErrorRef, {
    customMessage: customMessage,
    errorMessage: error.message || "No error",
    errorStack: error.stack || "No stack",
    errorName: error.name || "No name",
    errorCause: error.toString() || "No cause",
    user: state?.currentUser?.gymId || "No user",
    state: state.currentUser || "No state",
    date: new Date(),
  });
  if(window.location.hostname !== "dashboard.moregyms.com") {
    alert(`${customMessage} \n ${error.message}`);
  }
}
