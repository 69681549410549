import React from 'react';
import './style.scss';
import { useStoreContext } from '../../../../Utilities/globalState';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
export default function ToDoList() {
    const [state, dispatch] = useStoreContext();

    const toDoItems = [
        { label: 'Add your profile picture', condition: state.currentUser.gymLogoURL !== '', link: '../settings#details' },
        { label: 'Add a welcome or walkthrough video', condition: state.currentUser.videoUrl !== '', link: '../media' },
        { label: 'Add photos of your gym', condition: state.currentUser.additionalPhotos.length > 0, link: '../media' },
        { label: 'Add your amenities', condition: Object.values(state.currentUser.amenities).some((value) => value === true), link: '../info' },
        { label: 'Add your equipment', condition: Object.values(state.currentUser.equipment).some((value) => value === true), link: '../info' },
        { label: 'Add your services', condition: Object.values(state.currentUser.services).some((value) => value === true), link: '../info' },
        { label: 'Add your class schedule', condition: state.currentUser.classList.length !== 0, link: '../schedule' },
        { label: 'Connect to Stripe to recieve payouts', condition: state.currentUser.stripeData !== null, link: '../settings#payment-settings' },
        { label: 'Add your drop-in rates', condition: state.currentUser.paymentPackages.length > 0, link: '../settings#payment-settings' },
    ];

    const completedToDos = toDoItems.filter(item => item.condition);

    const progressPercentage = (completedToDos.length / toDoItems.length) * 100;

    return (
        <>
            <div className='to-do-body'>
                <div className='to-do-heading'>
                    <h3>To-do list</h3>
                    <p>Let's get your page all set up!</p>
                    {/* <p>To claim your prize, complete following steps below.</p> */}
                    <div className='to-do-progress'>
                        <div className='progress-wrapper'>
                            <div className='progress-bar red-progress-bar' style={{ width: `${progressPercentage}%` }}>
                            </div>
                        </div>
                        <p>{progressPercentage.toFixed(1)}%</p>
                    </div>
                </div>

                <div className='to-do-container'>
                    {progressPercentage !== 100 && toDoItems.map((item, index) => (
                        <div className='to-do-row' key={index}>
                            <Link to={item.link}>
                                {item.condition ? (
                                    <div className='to-do-finished'>
                                        <div className='circle-filled'>
                                            <i className="bi bi-square-fill"></i>
                                        </div>
                                        <h4>{item.label}</h4>
                                    </div>
                                ) : (
                                    <div className='to-do-content'>
                                        <div className='circle-empty'></div>
                                        <h4>{item.label}</h4>
                                    </div>
                                )}
                            </Link>
                        </div>
                    ))}
                    {progressPercentage === 100 && (
                        <div className='all-completed'>
                            <h2>You did it!</h2>
                            <p>All tasks are completed!</p>
                            {/* reward not implemented yet? */}
                            {/* <button className="custom-button">
                                <Link to="/info">CLAIM YOUR REWARD <i className="bi bi-trophy"></i></Link>
                            </button> */}
                        </div>
                    )}
                </div>


            </div>
        </>
    );

}
