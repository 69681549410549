import {
  useReducer
} from 'react'
import {
  SET_CURRENT_USER,
  LOGIN_STATUS,
  COLLAPSE_NAV,
  UPDATE_PAYMENT,
  UPDATE_ADDITIONAL_PHOTOS
} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser
      };
    case "SET_GYM_MEMBERS":
      return {
        ...state,
        gymMembers: action.gymMembers
      };
    case "SET_GYMS":
      return {
        ...state,
        gymsList: action.gymsList
      };
    case LOGIN_STATUS:
      return {
        ...state,
        loggedIn: action.loggedIn
      };
    case COLLAPSE_NAV:
      return {
        ...state,
        navCollapse: action.navCollapse
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          paymentData: action.paymentData
        }
      };
    case "ADD_PROPERTIES":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          paymentData: {
            subscriptionId: "",
            startDate: 0,
            lastInvoice: "",
          },
        }
      };
    case UPDATE_ADDITIONAL_PHOTOS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          additionalPhotos: action.payload
        }
      };
    default:
      return state;
  }
};


export function useModalReducer(initialContext) {
  return useReducer(reducer, initialContext)
}