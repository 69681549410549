import React, { useEffect, useState } from 'react'
import { Spinner, InputGroup, FloatingLabel, Form } from 'react-bootstrap'
import { useStoreContext } from '../../../../Utilities/globalState'
import { parseDataForDB } from '../../../../Utilities/helpers'
import { db } from '../../../../firebase-config'
import { setDoc, doc } from '@firebase/firestore'
import { GYMS_REF } from '../../../../Utilities/dbRefs'
import './styles.scss'

export const Details = () => {
    const [state, dispatch] = useStoreContext()
    const [uploading, setUploading] = useState(false)
    const [successfullUpload, setSuccessfullUpload] = useState(false)


    const [gymBio, setGymBio] = useState(
        state.currentUser.bio || '',
    )

    const [instagramUsername, setInstagramUsername] = useState(
        state.currentUser.instagram || ''
    )
    const handleInstagramUsername = (e: any) => {
        setInstagramUsername(e.target.value);
    }

    const [releaseWaiver, setReleaseWaiver] = useState(
        (state.currentUser.legal && state.currentUser.legal.releaseWaiver) || '',
    )


    const [privacyPolicy, setPrivacyPolicy] = useState(
        (state.currentUser.legal && state.currentUser.legal.privacyPolicy) || '',
    )

    const handlePrivacyPolicy = (e: any) => {
        setPrivacyPolicy(e.target.value);
    }

    const [terms, setTerms] = useState(
        (state.currentUser.legal && state.currentUser.legal.terms) || '',
    )

    const handleTerms = (e: any) => {
        setTerms(e.target.value);
    }

    const [refundPolicy, setRefundPolicy] = useState(
        (state.currentUser.legal && state.currentUser.legal.refundPolicy) || '',
    )
    const handleRefundPolicy = (e: any) => {
        setRefundPolicy(e.target.value);
    }

    const handleSave = async () => {
        setUploading(true)
        setSuccessfullUpload(false)
        const gymRef = doc(db, GYMS_REF, state.currentUser.gymId)
        setDoc(gymRef, parseDataForDB(state), { merge: true })
    }

    const handleSaveBtn = async () => {
        handleSave()
            .then(() => {
                setTimeout(() => {
                    setUploading(false)
                    setSuccessfullUpload(true)
                }, 1000)
            })
    }
    useEffect(() => {
        setSuccessfullUpload(false)
        dispatch({
            type: 'SET_CURRENT_USER',
            currentUser: {
                ...state.currentUser,
                gymBio: gymBio,
                instagramUsername: instagramUsername,
                legal: {
                    releaseWaiver: releaseWaiver,
                    privacyPolicy: privacyPolicy,
                    terms: terms,
                    refundPolicy: refundPolicy,
                }
            },
        })
    }, [
        gymBio,
        instagramUsername,
        releaseWaiver,
        privacyPolicy,
        terms,
        refundPolicy,
    ])


    return (
        <div className="details-body">
            <div className='details-heading'>
                <div className='column'>
                    <h4>Details</h4>
                    <p>Add your important business details below.</p>
                </div>
                <button onClick={handleSaveBtn} className='custom-button-second'>
                    {'Save  '}
                    {uploading && (
                        <Spinner size="sm" animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                    {successfullUpload && <i className="bi bi-check2"></i>}
                </button>
            </div>

            <div className='details-header'>
                <h5>Bio</h5>
                <p>Having a bio allows you to connect with customers on a personal level, giving them insight on your origin story, your mission, etc.</p>
            </div>
            <div className='bio'>
                <InputGroup className="mb-3">
                    <FloatingLabel label="Bio">
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            placeholder="Bio"
                            value={gymBio}
                            onChange={(e: { target: { value: any } }) => setGymBio(e.target.value)}
                        />
                    </FloatingLabel>
                </InputGroup>
            </div>
            <div className='details-header'>
                <h5>Instagram username</h5>
                <p>Allow customers to check out your profile directly from your MoreGym's page.</p>
            </div>
            <div className='links'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="social">@</InputGroup.Text>
                    <FloatingLabel label={`Username`}>
                        <Form.Control
                            type="text"
                            value={instagramUsername}
                            onChange={handleInstagramUsername}
                            placeholder={`@`}
                        />
                    </FloatingLabel>
                </InputGroup>
            </div>
            <div className='details-header'>
                <h5>Legal forms</h5>
                <p>These forms will be shown to the user prior to purchasing passes to your gym.</p>
            </div>
            <div className='waiver'>
                <InputGroup className="mb-3">
                    <FloatingLabel label="Release Waiver">
                        <Form.Control
                            as="textarea"
                            style={{ height: '300px' }}
                            placeholder="Release Waiver"
                            value={releaseWaiver}
                            onChange={(e: { target: { value: any } }) => setReleaseWaiver(e.target.value)}
                        />
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="social">https://</InputGroup.Text>
                    <FloatingLabel label={`Privacy Policy`}>
                        <Form.Control
                            type="text"
                            value={privacyPolicy}
                            onChange={handlePrivacyPolicy}
                            placeholder={`Privacy Policy`}
                        />
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3">
                <InputGroup.Text id="social">https://</InputGroup.Text>

                    <FloatingLabel label={`Terms & Conditions`}>
                        <Form.Control
                            type="text"
                            value={terms}
                            onChange={handleTerms}
                            placeholder={`Terms & Conditions`}
                        />
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3">
                <InputGroup.Text id="social">https://</InputGroup.Text>
                    <FloatingLabel label={`Refund Policy`}>
                        <Form.Control
                            type="text"
                            value={refundPolicy}
                            onChange={handleRefundPolicy}
                            placeholder={`Refund Policy`}
                        />
                    </FloatingLabel>
                </InputGroup>
            </div>
        </div>
    )
}
