import { useState } from 'react'
import { Nav } from 'react-bootstrap'
import { PageTemplate } from '../../PageTemplate'
import { Photos } from './Photos'
import { Video } from './Video'
import './styles.scss'
export const Media = () => {
    const [page, setPage] = useState(1)
    const renderPage = () => {
        switch (page) {
            case 1:
                return <Photos />
            case 2:
                return <Video />
            default:
                return <Photos />
        }
    }

    return (
        <PageTemplate>
            <Nav justify variant="tabs" defaultActiveKey="link-1">
                <Nav.Item className="info-nav-link">
                    <Nav.Link className='nav-link-link' onClick={() => setPage(1)} eventKey="link-1">Photos</Nav.Link>
                </Nav.Item>
                <Nav.Item className="info-nav-link" >
                    <Nav.Link className='nav-link-link' onClick={() => setPage(2)} eventKey="link-2">Video</Nav.Link>
                </Nav.Item>
            </Nav>
            {renderPage()}
        </PageTemplate>
    )
}
