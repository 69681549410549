import React, { useState } from 'react'
import { useStoreContext } from '../../../../Utilities/globalState'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles.scss'

export const ChangePassword = () => {
  const [state] = useStoreContext()
  const [emailSent, setEmailSent] = useState(false)
  const notify = () => toast.success("Check your email!");

  const auth = getAuth()
  function resetPassword() {
    if (state.currentUser.email) {
      sendPasswordResetEmail(auth, state.currentUser.email)
        .then(() => {
          setEmailSent(true);
          notify();
        })
        .catch((error) => {
          // const errorCode = error.code
          // const errorMessage = error.message
          // ..
        })
    }
  }

  return (
    <div className="change-password-body">
      <div className='change-password-container'>
        <div className='icon-gym-password'>
          <i className="bi bi-lock"></i>
        </div>
        <h3>Update Your Password</h3>
        <div className="info">
          To change your password, click the button below and we will send you an
          email with further instructions on changing your password.
        </div>
        <button className='custom-button' disabled={emailSent} onClick={resetPassword }>
          Change Password
        </button>
      </div>
      <ToastContainer/>
    </div>
  )
}
