import React, { useState, useEffect, useRef } from 'react'
import { Form, InputGroup, Alert } from 'react-bootstrap'
import { auth, db } from '../../../firebase-config'
import { addDoc, collection, query, where, getDocs } from '@firebase/firestore'
import { Link } from 'react-router-dom'
import { createUserWithEmailAndPassword } from '@firebase/auth'
import { useStoreContext } from '../../../Utilities/globalState'
import { parseDataForDB, reportNonBlockingError } from '../../../Utilities/helpers'
import { GYMS_REF } from '../../../Utilities/dbRefs'
import { useParams } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import { httpsCallable, HttpsCallable } from 'firebase/functions'
import { functions } from '../../../firebase-config'
import magFitLogo from '../../../Images/newMagfitLogo.png'
import './styles.scss'

export const Signup1 = () => {
  const [state, dispatch] = useStoreContext()
  const [email, setEmail] = useState('')
  const [emailErr, setEmailErr] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState(false)
  const [passKey,] = useState(state.currentUser.passKey || '')
  const [gymType, setGymType] = useState(state.currentUser.gymType || ['CrossFit'])
  const [hidePW, setHidePW] = useState(true)
  const [successfulSignIn, setSuccessfulSignIn] = useState(
    state.currentUser.gymOwnerId ? true : false,
  )
  const [valid, setValid] = useState(false)
  const nextRef = useRef<any>()
  const { emailAddress } = useParams();

  const handleSubmit = (e: any) => {
    if (!checkPassword()) {
      e.preventDefault()
      setPasswordErr(true);
    } else {
      setPasswordErr(false);
      const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.preventDefault()
        e.stopPropagation()
      }
      setValid(true)
      e.preventDefault()
      if (form.checkValidity() === true) {
        signInWithEmail()
      }
    }
  }
  const checkPassword = () => {
    const specialChars = new Set(['@', '#', '!', '~', '$', '?', '%', '+', '&', '*']);
    const numChars = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
    let special = false;
    let upper = false;
    let lower = false;
    let nums = false;
    let length = password.length >= 6;
    for (let char of password) {
      if (specialChars.has(char)) special = true;
      if (numChars.has(char)) nums = true;
      if (char.toLowerCase() !== char) upper = true;
      if (char.toUpperCase() !== char) lower = true;
    }
    return special && upper && lower && nums && length;
  }

  const signInWithEmail = async () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        setSuccessfulSignIn(true)
        setEmailErr(false)
        return credentials
      })
      .then((credentials) => {
        // Create new gym with valid sign in
        createGym()
          .then(async ([id, data]) => {
            dispatch({
              type: 'SET_CURRENT_USER',
              currentUser: {
                ...state.currentUser,
                gymId: id,
                gymOwnerId: credentials.user.uid,
              },
            });

            const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
            await sendWelcomeEmail({ toEmail: email });
            // nextRef.current.click()
          })
          .catch((err) => {
            reportNonBlockingError(err, state, "Signup1 -> signInWithEmail -> createGym")
          })
      })
      .catch((err) => {
        reportNonBlockingError(err, state, "Signup1 -> signInWithEmail -> createUserWithEmailAndPassword")
        setEmailErr(true)
      })
  }

  useEffect(() => {
    dispatch({
      type: 'SET_CURRENT_USER',
      currentUser: {
        ...state.currentUser,
        gymType,
        passKey,
        email,
      },
    })
    // eslint-disable-next-line
  }, [gymType, email, passKey])

  useEffect(() => {
    if (emailAddress) {
      setEmail(emailAddress);
    }
  }, [])

  const createGym = async () => {
    const allGymsCollectionRef = collection(db, GYMS_REF)
    const existingGymQuery = query(
      collection(db, GYMS_REF),
      where('currentUser.gymOwnerId', '==', state.currentUser.gymOwnerId),
    )

    const existingGym = await getDocs(existingGymQuery)
    if (!existingGym.empty) {
      let gyms: any = []
      existingGym.forEach((gym) => gyms.push([gym.id, gym.data()]))
      return gyms[0]
    } else {
      let parsedData = parseDataForDB(state)
      const docRef = await addDoc(allGymsCollectionRef, parsedData)
      if (docRef) return [docRef.id, state]
      else return null
    }
  }

  useEffect(() => {
    if (successfulSignIn) {
      window.location.hash = "#signup-2"
    }
  }, [successfulSignIn])

  return (
    <div id="signup-1" className="signup-1-body">
      <div className='step-counter'>
        <h1>Account set up</h1>
        <div className='step'>
          <p>Step 1 of 2</p>
          <div className='step-indicator'>
            <div className='step1'></div>
            <div className='step2'></div>
          </div>
        </div>
      </div>
      <div className="signup-1-main">

        <div className='content'>
          <div className='description'>
            <h4>Business type</h4>
            <p>Select what type of training your business is known for.</p>
          </div>
          <Form noValidate>
            <Form.Select
              onChange={(e: { target: { value: any } }) => {
                setGymType([e.target.value])
                // setGymErr(false)
              }}
              aria-label="Default select example"
            // defaultValue="1"
            >
              <option value="1" disabled>
                --Please Select--
              </option>
              <option value="CrossFit">Functional Fitness (HIIT)</option>
              <option value="Health Club">General Fitness (Body Building)</option>
              <option value="Personal Trainer">Personal Training</option>
              <option value="MMA">Mixed Martial Arts</option>
            </Form.Select>
          </Form>
          <div className='description'>
            <h4 className='account'>Account info</h4>
            <p>
              Please enter your email and a secure password below.
            </p>
          </div>
          <Form noValidate validated={valid} onSubmit={handleSubmit}>
            {/* Email */}
            {emailErr && <Alert variant="danger">Email already in use</Alert>}
            <InputGroup className="mb-3">
              <InputGroup.Text className="currency-style" id="email-icon-box">
                <i className="bi bi-envelope"></i>
              </InputGroup.Text>
              <Form.Control
                disabled={successfulSignIn}
                required
                id="email-input"
                type="email"
                placeholder="Email"
                aria-label="email"
                aria-describedby="email-icon-box"
                value={email}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) => setEmail(e.target.value)}
              // isValid={!emailErr}
              />
            </InputGroup>
            {/* Password */}
            <InputGroup className="mb-3">
              <InputGroup.Text className="currency-style" id="password-icon-box">
                <i className="bi bi-lock"></i>
              </InputGroup.Text>
              <Form.Control
                disabled={successfulSignIn}
                required
                id="password-input"
                type={hidePW ? 'password' : 'text'}
                placeholder="Password"
                aria-label="password"
                aria-describedby="password-icon-box"
                autoComplete="true"
                value={password}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) => setPassword(e.target.value)}
              />
              <InputGroup.Text
                onClick={() => setHidePW(!hidePW)}
                id="password-icon-box"
                className="currency-style"
              >
                {!hidePW && <i className="bi bi-eye"></i>}
                {hidePW && <i className="bi bi-eye-slash"></i>}
              </InputGroup.Text>
            </InputGroup>
            {!passwordErr && (
              <Alert
                className="mb-3"
                variant="success"
              >
                <ul>
                  <li>Must be at least 6 characters.</li>
                  <li>Must contain a number</li>
                  <li>Must contain upper and lower case letters</li>
                  <li>Must include a special character: @, #, !, ~, $, ?, %, +, &, *</li>
                </ul>
              </Alert>
            )}
            {passwordErr && (
              <Alert
                className="mb-3"
                variant="danger"
              >
                <ul>
                  <li>Must be at least 6 characters.</li>
                  <li>Must contain a number</li>
                  <li>Must contain upper and lower case letters</li>
                  <li>Must include a special character: @, #, !, ~, $, ?, %, +, &, *</li>
                </ul>
              </Alert>
            )}
            {/* Passkey */}
            {/* <InputGroup className="mb-3">
            <InputGroup.Text id="passkey-icon-box">
              <i className="bi bi-lock"></i>
            </InputGroup.Text>
            <Form.Control
              disabled={successfulSignIn}
              required
              id="passkey-input"
              type="text"
              placeholder="Pass Key"
              aria-label="passkey"
              aria-describedby="password-icon-box"
              value={passKey}
              onChange={(e) => setPassKey(e.target.value)}
            />
          </InputGroup> */}
            <div className="signup-buttons">
              {!successfulSignIn && <button className='custom-button' type="submit">Sign Up</button>}
              {/* {successfulSignIn && <button className='custom-button' onClick={()=> window.location.hash = "#signup-2"} >Next</button>} */}
            </div>
            <div className='terms-signup'>By signing up you agree to our <a href='https://moregyms.com/privacy'>terms and service</a></div>
            {/* <hr />
          <span>
            Already have an account? <Link to="/login">Log in</Link>
          </span> */}
            {/* <button type="button" onClick={() => signInWithGoogle()}>
            Google Sign In
          </button> */}
          </Form>
        </div>
      </div>
    </div>
  )
}
