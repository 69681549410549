import { useState, useEffect } from 'react';
import { PageTemplate } from '../../PageTemplate';
import './styles.scss';
import { Spinner } from 'react-bootstrap';
import { collection, query, where, getDocs, Timestamp, getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { useStoreContext } from '../../../Utilities/globalState';
import { Progress, Rate, Avatar } from 'antd';
import { Empty, Typography, Card } from 'antd';

interface Review {
    id: string;
    classesRating: number;
    cleanlinessRating: number;
    communityRating: number;
    createdAt: Timestamp;
    friendlinessRating: number;
    gymID: string;
    reviewText: string;
    trainersRating: number;
    userUID: string;
}
interface User {
    firstName: string;
    lastName: string;
    age: number;
    gender: string;
}

export const Reviews = () => {
    const [state, dispatch] = useStoreContext();
    const gymId = state.currentUser?.gymId;
    const [reviews, setReviews] = useState<Review[]>([]);
    const [users, setUsers] = useState<{ [key: string]: User }>({});
    const [loading, setLoading] = useState(true);
    const [totalReviews, setTotalReviews] = useState(0);
    const [averageRating, setAverageRating] = useState(0);
    const [averageClassesRating, setAverageClassesRating] = useState(0);
    const [averageCleanlinessRating, setAverageCleanlinessRating] = useState(0);
    const [averageCommunityRating, setAverageCommunityRating] = useState(0);
    const [averageFriendlinessRating, setAverageFriendlinessRating] = useState(0);
    const [averageTrainersRating, setAverageTrainersRating] = useState(0);
    useEffect(() => {
        const fetchReviews = async () => {
            if (!gymId) return;

            setLoading(true);
            try {
                const q = query(collection(db, 'GymReviews'), where('gymID', '==', gymId));
                const querySnapshot = await getDocs(q);
                const reviewsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Review));
                setReviews(reviewsList);
                const userUIDs = reviewsList.map(review => review.userUID);
                const uniqueUserUIDs = Array.from(new Set(userUIDs));

                const usersData: { [key: string]: User } = {};
                for (const userUID of uniqueUserUIDs) {
                    const userDoc = doc(db, 'Users', userUID);
                    const userSnapshot = await getDoc(userDoc);
                    if (userSnapshot.exists()) {
                        usersData[userUID] = userSnapshot.data() as User;
                    }
                }
                setUsers(usersData);
                const total = reviewsList.length;
                const average = total > 0 ?
                    reviewsList.reduce((sum, review) =>
                        sum + (review.classesRating + review.cleanlinessRating + review.communityRating + review.friendlinessRating + review.trainersRating) / 5, 0) / total
                    : 0;

                const avgClassesRating = total > 0 ? reviewsList.reduce((sum, review) => sum + review.classesRating, 0) / total : 0;
                const avgCleanlinessRating = total > 0 ? reviewsList.reduce((sum, review) => sum + review.cleanlinessRating, 0) / total : 0;
                const avgCommunityRating = total > 0 ? reviewsList.reduce((sum, review) => sum + review.communityRating, 0) / total : 0;
                const avgFriendlinessRating = total > 0 ? reviewsList.reduce((sum, review) => sum + review.friendlinessRating, 0) / total : 0;
                const avgTrainersRating = total > 0 ? reviewsList.reduce((sum, review) => sum + review.trainersRating, 0) / total : 0;

                setTotalReviews(total);
                setAverageRating(average);
                console.log('Type of Average Rating:', typeof averageRating);
                setAverageClassesRating(avgClassesRating);
                setAverageCleanlinessRating(avgCleanlinessRating);
                setAverageCommunityRating(avgCommunityRating);
                setAverageFriendlinessRating(avgFriendlinessRating);
                setAverageTrainersRating(avgTrainersRating);
            } catch (error) {
                console.error("Error fetching reviews: ", error);
            }
            setLoading(false);
        };

        fetchReviews();
    }, [gymId]);
    const formatTimestamp = (timestamp: Timestamp) => {
        const date = timestamp.toDate();
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${month}/${day}/${year}`; // Format: MM/DD/YYYY
    };
    const RatingProgress = ({ rating, label }: { rating: number; label: string }) => {
        const getStrokeColor = (label: string) => {
            switch (label) {
                case 'Classes':
                    return '#FFA500';
                case 'Cleanliness':
                    return '#0000FF';
                case 'Community':
                    return '#800080';
                case 'Friendliness':
                    return '#4caf50';
                case 'Trainers':
                    return '#FF0000';
                default:
                    return '#001342';
            }
        };

        return (
            <div className="reviews-progress">
                <p className='label'>{label}</p>
                <Progress percent={(rating / 5) * 100} showInfo={false} strokeColor={getStrokeColor(label)} />
                <p className='rating'>{rating.toFixed(1)}</p>
            </div>
        );
    };

    return (
        <PageTemplate>
            <div className='community-body'>
                <h1>Reviews</h1>
                <div className='review-stats'>
                    <div className='stat-1'>
                        <p>Total Reviews</p>
                        <h3>{totalReviews}</h3>
                    </div>
                    <div className='stat-1'>
                        <p>Average Rating</p>
                        <div className='star-rating'>
                            <h3>{averageRating.toFixed(2)}</h3>
                            <Rate disabled allowHalf value={averageRating} />
                        </div>
                    </div>
                    <div className='stat'>
                        <RatingProgress rating={averageClassesRating} label="Classes" />
                        <RatingProgress rating={averageCleanlinessRating} label="Cleanliness" />
                        <RatingProgress rating={averageCommunityRating} label="Community" />
                        <RatingProgress rating={averageFriendlinessRating} label="Friendliness" />
                        <RatingProgress rating={averageTrainersRating} label="Trainers" />
                    </div>
                </div>
                <div className='reviews'>
                    {loading ? <div className='spinner-container'><Spinner size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div> : (
                        reviews.length > 0 ? (
                            reviews.map(review => {
                                // Calculate overall rating
                                const overallRating = (
                                    review.classesRating +
                                    review.cleanlinessRating +
                                    review.communityRating +
                                    review.friendlinessRating +
                                    review.trainersRating
                                ) / 5;
                                const user = users[review.userUID] || {
                                    firstName: 'Unknown',
                                    lastName: 'Unknown',
                                    age: 'Unknown',
                                    gender: 'Unknown',
                                };
                                const initials = `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`;

                                return (
                                    <div key={review.id} className='review'>
                                        <div>
                                            <div className='review-group'>
                                                <h4>{user.firstName} {user.lastName}</h4>
                                                <p className='dot-space'></p>
                                                <p>{user.gender}</p>
                                            </div>
                                            <div className='review-group'>
                                                <Rate disabled allowHalf defaultValue={overallRating} />
                                                <p className='dot-space'></p>
                                                <p>{formatTimestamp(review.createdAt)}</p>
                                            </div>
                                            <p>{review.reviewText}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='spinner-container'>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <Typography.Text>
                                            No Reviews Found
                                        </Typography.Text>
                                    }
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </PageTemplate>
    );
};
