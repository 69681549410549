import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { PageTemplate } from '../../PageTemplate'
import { Nav } from 'react-bootstrap'
import './styles.scss'
import { Feedback } from './Feedback/index'
import { ChangePassword } from './ChangePassword'
// import { GymSettings } from './GymSettings' // Commented out GymSettings
import PaymentSettings from './PaymentSettings'
import { Info } from './Info/index'
import { auth } from '../../../firebase-config'
import { signOut } from '@firebase/auth'
import { Plans } from './Plans/'

export const Settings = () => {
  const [page, setPage] = useState(1)
  const { hash } = useLocation()
  const navigate = useNavigate();

  const renderPage = () => {
    switch (page) {
      case 1:
        return <Plans /> // Removed GymSettings case
      case 2:
        return <Info />
      case 3:
        return <ChangePassword />
      case 4:
        return <Feedback />
      case 5:
        return <PaymentSettings />
      default:
        return <Info /> // Default to Info page if page is not recognized
    }
  }
  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear()
      window.location.reload()
    })
  }

  useEffect(() => {
    switch (hash) {
      case '#contact':
        setPage(4)
        break;
      case '#payment-settings':
        setPage(5)
        break;
      case '#password':
        setPage(3)
        break;
      case '#details':
        setPage(2)
        break;
      case '#plan':
        setPage(1)
        break;
        break;
      default:
        setPage(1) // Default to Upgrade page if hash is not recognized
    }
  }, [hash])

  return (
    <PageTemplate>
      <Nav justify variant="tabs" activeKey={`link-${page}`}>
        <Nav.Item className="info-nav-link">
        <Nav.Link onClick={() => navigate('/settings#plan')} eventKey="link-1">
            Plan
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link onClick={() => navigate('/settings#details')} eventKey="link-2">
            Details
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link onClick={() => navigate('/settings#payment-settings')} eventKey="link-5">
            Payments
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link onClick={() => navigate("/settings#password")} eventKey="link-3">
            Password
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link onClick={() => navigate("/settings#contact")} eventKey="link-4">
            Contact
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link onClick={signUserOut}>
            Log out
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {renderPage()}
    </PageTemplate>
  )
}
