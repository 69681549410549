import { useState } from 'react'
import { Nav } from 'react-bootstrap'
import { PageTemplate } from '../../PageTemplate'
import { Amenities } from './Amenities'
import { Services } from './Services'
import { Hours } from './Hours'
import Equipment from './Equipment'
import {Details} from './Details'

import Analytics from '../Home/Analytics'
import './styles.scss'

export const InfoPages = () => {
  const [page, setPage] = useState(1)
  const renderPage = () => {
    switch (page) {
      case 1:
        return <Amenities />
      case 2:
        return <Services />
      case 3:
        return <Hours />
      case 4:
        return <Equipment />
      case 5:
        return <Details />
      default:
        return <Amenities />
    }
  }
  return (
    <PageTemplate>
      <Nav justify variant="tabs" defaultActiveKey="link-1">
        <Nav.Item className="info-nav-link">
          <Nav.Link className='nav-link-link' onClick={() => setPage(1)} eventKey="link-1">Amenities</Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link" >
          <Nav.Link className='nav-link-link' onClick={() => setPage(2)} eventKey="link-2">Services</Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link className='nav-link-link' onClick={() => setPage(3)} eventKey="link-3">Hours</Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link className='nav-link-link' onClick={() => setPage(4)} eventKey="link-4">Equipment</Nav.Link>
        </Nav.Item>
        <Nav.Item className="info-nav-link">
          <Nav.Link className='nav-link-link' onClick={() => setPage(5)} eventKey="link-5">Details</Nav.Link>
        </Nav.Item>
      </Nav>
      {renderPage()}
    </PageTemplate>
  )
}
